import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import QuoteSlider from '../components/QuoteSlider/QuoteSlider';
import styled from "styled-components";
import useAllClients from '../hooks/use-all-clients';
import useAllServiceSlider from '../hooks/use-all-services-slider';
import { ClientSlider } from "../components/ClientsSlider/ClientSlider";
import { MainContentBlockHandler } from "../components/MainContentBlockHandler/MainContentBlockHandler";
import { RichTextBanner } from "../components/RichTextBanner/RichTextBanner";
import { StaticImageBanner } from "../components/StaticImageBanner/StaticImageBanner";
import { TileSlider } from "../components/TileSlider/TileSlider";
import { transformTileContent } from '../utils/transform-data-functions';

const ServiceTemplateStyles = styled.div`
  .service-slider {
    .section {
      padding-bottom: 0px;
      border-bottom: 0px;
      .container {
        padding-bottom: 60px;
        border-bottom: 0px;
      }
    }
  }
`;

const Servicetemplate = (services) => {
  const {
    title,
    subHeader,
    introductionParagraph,
    introductionParagraphSubtext,
    quotes,
    url,
    videoUrl,
    bannerImage,
    mainContentBlock,
  } = services;

  const clients = useAllClients();
  const serviceTiles = transformTileContent(useAllServiceSlider());
  return (
    <ServiceTemplateStyles>
       <StaticImageBanner image={bannerImage} title={title} video={videoUrl} />
        <RichTextBanner title={title} subHeader={subHeader} introPara={introductionParagraph} subPara={introductionParagraphSubtext} hasDot={true} />
        {quotes && <QuoteSlider slides={quotes} />}
        <MainContentBlockHandler content={mainContentBlock} />
        <TileSlider heading="Our Services" tiles={serviceTiles} />
        <ClientSlider heading="Clients." logos={clients} />
    </ServiceTemplateStyles>
  )
}

export default Servicetemplate
