import React from "react"
import { graphql } from "gatsby"
import ServiceTemplate from "../../templates/service-template"
import Seo from "../../components/SEO"
import Layout from "../../components/Layout"

const Service = ({ data: { contentfulService } }) => {
  return (
    <>
      <Seo title={contentfulService.title} />
      <Layout>
        <ServiceTemplate {...contentfulService} />
      </Layout>
    </>
  )
}

export const data = graphql`
  query serviceQuery($id: String) {
    contentfulService(id: { eq: $id }) {
      title
      url
      subHeader
      introductionParagraph {
        raw
      }
      introductionParagraphSubtext {
        raw
      }
      videoUrl
      bannerImage {
        gatsbyImageData
        file {
          url
        }
        description
      }
      quotes {
        quote {
          quote
        }
        author
      }
      mainContentBlock {
        ... on ContentfulTextBlock {
          id
          internal {
            type
          }
          title
          singleColumn
          mainContent {
            raw
          }
        }
        ... on ContentfulAlignedImageBlock {
          id
          internal {
            type
          }
          title
          alignment
          mainText {
            raw
          }
          images {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
            description
            file {
              url
            }
          }
        }
        ... on ContentfulImageBlock {
          internal {
            type
          }
          title
          image {
            gatsbyImageData(
              width: 2000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
            description
            file {
              url
            }
          }
        }
        ... on ContentfulVideoBlock {
          internal {
            type
          }
          title
          videoUrl
          description
        }
      }
      listingImage {
        description
        gatsbyImageData(
          width: 1000
          height: 680
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default Service
